import React, { useContext, useEffect } from "react";
import { StoresContext } from "../_app";

const LoginPage = () => {
  const rootStore = useContext(StoresContext);

  useEffect(() => {
    window.location.href = rootStore.userStore.getAuthUrl();
  }, []);

  return <div />;
};

export default LoginPage;
